.attributesText {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a3b2c;
}

.attributeInfo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 16px;
  margin-top: 16px;
}


.attributeItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  animation: fadeIn 1s ease-in-out;
}

.attributeItem strong {
  font-size: 1.1em;
}

.iconWrapper {
  margin-right: 10px;
}

.icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  animation: bounce 1.5s infinite;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

