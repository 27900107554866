@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  60% {
    transform: scale(0.9);
  }
}

.backButton {
  position: fixed;
  z-index: 10;
  top: 10px;
  left: 10px;
  background: #f4e4bc;
  border: 2px solid #b59e60;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a3b2c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: blink 3s infinite;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
}
