.common {
  color: saddlebrown;
}

.rare {
  color: gray;
}

.elite {
  color: orange;
}

.legendary {
  color: cornflowerblue;
}

.divine {
  color: green;
}
