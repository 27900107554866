.navbarItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.navbarIcon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 5px;
  animation: shake 2s infinite;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: rotate(-5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(5deg); }
}