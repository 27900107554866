.classesText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  gap: 20px;
}

.classInfo > p {
  margin-bottom: 0.5rem;
}

.classInfo > ul {
  list-style-type: none;
  padding: 0;
}

.classInfo > ul > li {
  margin-bottom: 0.5rem;
}

.classes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.classDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: min-content;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 8px;
  }
}

.classesIcon {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  > img {
    width: 50px;
    height: 50px;
  }
}

.newtonBallLeft {
  animation: swingLeft 2s infinite;
}

.newtonBallRight {
  animation: swingRight 2s infinite;
  animation-delay: 1s; /* Добавляем задержку для правой иконки */
}

.newtonBallInner {
  animation: shake 2s infinite;
}

@keyframes swingLeft {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-20px); }
  50% { transform: translateX(0); }
}

@keyframes swingRight {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(20px); }
  50% { transform: translateX(0); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: rotate(-5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(5deg); }
}
