.tableContainer {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 16px;
}

.text {
  font-size: 24px;
  margin: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
  overflow-x: auto;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

@media (max-width: 600px) {
  .tableContainer {
    padding: 8px;
    margin-bottom: 16px;
  }

  .text {
    font-size: 20px;
  }

  .icon {
    font-size: 20px;
  }

  .table th,
  .table td {
    padding: 8px;
    font-size: 12px;
    white-space: normal;
    text-overflow: clip;
  }

  .table {
    display: block;
    overflow-x: auto;
  }
}
