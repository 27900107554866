.container {
  padding: 16px;
  overflow: auto;
  max-width: 800px;
  margin: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 20px; // Пространство между таблицами
  height: 100%;
}

.container::-webkit-scrollbar {
  display: none;
}

.itemContainer {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px; // Отступы между элементами
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

p {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  word-break: break-word; // Предотвращение переполнения текста
}

.image {
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
  max-height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
  overflow-x: auto; // Добавлено для предотвращения переполнения по оси X
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

@media (max-width: 600px) {
  .table th,
  .table td {
    padding: 4px;
    font-size: 12px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  .image {
    max-width: 150px;
    max-height: 150px;
  }

  .container {
    gap: 16px; // Уменьшение отступов на мобильных устройствах
  }
}

.text {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 4px;
  border-radius: 8px;
  text-align: center;
}

.lootTable {
  display: flex;
  flex-direction: column;
  gap: 20px; // Отступы между таблицами лута
}
