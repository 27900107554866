.socialLinks {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
}

.isGame {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}