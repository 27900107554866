.imagesWrapper {
  height: 170px;
  min-height: 170px;
  position: relative;
}

.image {
  position: absolute;
  width: 100px; /* Adjust as needed */
  height: 150px; /* Adjust as needed */
  transform-origin: center bottom;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &:nth-child(1) {
    transform: translateX(-50%) rotate(-40deg);
  }
  &:nth-child(2) {
    transform: translateX(-50%) rotate(-20deg);
  }
  &:nth-child(3) {
    transform: translateX(-50%) rotate(0deg);
  }
  &:nth-child(4) {
    transform: translateX(-50%) rotate(20deg);
  }
  &:nth-child(5) {
    transform: translateX(-50%) rotate(40deg);
  }
}

.imgWrap {
  width: 100%;
  height: 100%;
  animation: breathe 3s ease-in-out infinite;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@keyframes breathe {
  0%, 100% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.05) translateY(-5px);
  }
}
