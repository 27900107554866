.button {
  background-color: #8b4513;
  border: 2px solid #5c3317;
  border-radius: 10px;
  padding: 10px 40px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #a0522d;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
  }

  &:active {
    background-color: #5c3317;
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
