.row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  position: relative;
  &:last-child {
    margin-bottom: 24px;
  }
}

.firstImage {
  width: 200px;
  height: 300px;
  object-fit: contain;
  transform: scaleX(-1);
  border-radius: 10%;
  transition: opacity 1s ease-out;
  @media (max-width: 767px) {
    position: absolute !important;
    width: 50px !important;
    height: 100px !important;
    right: 30px !important;
    top: 20px !important;
    > img {
      width: 50px !important;
      height: 100px !important;
    }
  }
}

.leftIcon {
  @media (max-width: 767px) {
    left: 25px;
  }
}

.hidden {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.visible {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.papyrusWrapper {
  background: #f4e4bc;
  padding: 10px;
  border: 5px solid #b59e60;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 510px;
  font-family: 'Papyrus', sans-serif;
  color: #4a3b2c;
  text-align: justify;
  background: linear-gradient(to bottom, #f4e4bc 0%, #f1d9a5 100%);
  transition: opacity 1s ease-out;
}

.papyrusContent {
  padding: 10px;
  border: 1px solid #b59e60;
  border-radius: 10px;
}

.border {
  padding: 20px;
  border: 2px solid #b59e60;
  border-radius: 15px;
  background: #f4e4bc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #4a3b2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  > h3 {
    white-space: nowrap;
  }
}
