.wrapper {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  padding: 0 8px;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.text {
  background: #f4e4bc;
  border: 2px solid #b59e60;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 15px;
}

